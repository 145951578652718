@keyframes loadingImg {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.animatingImg {
    animation: loadingImg 1s linear infinite;
}

@keyframes backGround-color {
    0% {
        background-color: lightgray;
    }

    50.0% {
        background-color: gray;
    }

    100.0% {
        background-color: darkgray;
    }
}

.backGround-color-change {
    animation: backGround-color 1s linear infinite;
}